@import "styles/variables";

.phone-input-component {
  label {
    padding-left: 40px !important;

    &.label-float-custom {
      padding-left: 5px !important;
    }
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  padding: 0 10px;
}