$footer-color: #009ddd;

$xs: 480px;
$sm: 766px;
$md: 991px;
$lg: 1200px;
$xl: 1440px;

$general-gutter: 15px;
$general-radius: 4px;
$general-shadow: 0 6.3px 10.4px rgba(0, 0, 0, 0.06),
  0 50px 80px rgba(0, 0, 0, 0.12);

$brand-color: #aaa;
$discount-color: rgb(230, 59, 69);
$new-color: #62fc7f;
$status-color: rgb(255, 199, 15);
$favourite-color: #50c7f7;
$gift-color: #db1ec2;

$available-color: rgb(40, 167, 69);
$unavailable-color: rgb(220, 53, 69);

$primary-color: #2d4265;
$primary-color-hover: #00b6de;

$secondary-color: #00b6de;

$text-color: rgb(17, 17, 17);
$blue-color: rgb(0, 89, 255);
$grey-color: rgb(183, 183, 183);
$grey-light-color: rgb(238, 238, 238);
$grey-dark-color: rgb(172, 172, 172);

$grey-border-color: #f0f0f0;
$main-bg-color: rgb(255,255,255);
